import { keyframes, Theme, ThemeContext } from '@emotion/react';
import { themeGet } from '@styled-system/theme-get';
import Image from 'next/legacy/image';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import Scroll from 'react-scroll';
import ColorMode from 'shared/color-modes';
import { Box, BoxProps, Flex } from 'shared/grid';
import Modal, { CloseButton } from 'shared/modal';
import { Heading, Link } from 'shared/typography';
import { extractVideoUrl } from 'shared/utils/video';
import DivToBottomRight from '../public/images/div-to-bottom-right.svg';
import { TOPBAR_HEIGHT } from './header';
import { UPLOAD_PATH_PREFIX } from 'shared/utils/constants';

export const getCDNImageUrl = (src: string) => {
  if (__TARGET_ENV__ === 'dev') {
    return src;
  }

  const src_ = src?.replace(UPLOAD_PATH_PREFIX, '');

  return __TARGET_ENV__ === 'staging'
    ? `https://uploads.backflip.co.za/${src_}`
    : `https://uploads.bookem.com/${src_}`;
};

export const nextImageProps = (value) => {
  const unoptimized = value.mimeType === 'image/svg+xml';
  if (__TARGET_ENV__ === 'dev') {
    return {
      width: value.naturalWidth,
      height: value.naturalHeight,
      src: value.url,
      unoptimized,
    };
  }

  let src = value.url?.replace(UPLOAD_PATH_PREFIX, '');

  if (value.mimeType === 'image/svg+xml') {
    src =
      __TARGET_ENV__ === 'staging'
        ? `https://uploads.backflip.co.za/${src}`
        : `https://uploads.bookem.com/${src}`;
  }

  return {
    width: value.naturalWidth,
    height: value.naturalHeight,
    src,
    unoptimized,
  };
};

const ScrollButton_ = ({ children, ...props }) => (
  <Link
    as="button"
    sx={{
      cursor: 'pointer',
    }}
    {...props}
  >
    {children}
  </Link>
);

export const ScrollButton = Scroll.ScrollLink(ScrollButton_);

// export function linesToParagraphs(...nodes) {
//   return nodes
//     .map((node, nodeIdx) =>
//       typeof node === 'string'
//         ? node
//             .split('\n')
//             .filter((text) => !!text.trim())
//             .map((text, textIdx) => <p key={`${nodeIdx}-${textIdx}`}>{text}</p>)
//         : node
//     )
//     .reduce((nodes, node) => nodes.concat(node), []);
// }

// export const Feature = ({ icon, iconColor = undefined, name, description }) => (
//   <Grid textAlign="left" gridTemplateColumns={'auto 1fr'} gridGap={4}>
//     <Flex color={iconColor}>
//       <FontAwesomeIcon icon={icon} fixedWidth size="3x" />
//     </Flex>

//     <Grid gridGap={2} gridTemplateRows="auto 1fr">
//       <Heading as="h3" fontSize={[2, 3]} fontWeight="heading">
//         {name}
//       </Heading>
//       <Box>
//         <p>{description}</p>
//       </Box>
//     </Grid>
//   </Grid>
// );

export function Paragraphs({
  ref,
  text,
  ...props
}: {
  ref?: React.Ref<HTMLDivElement>;
  text: string;
} & BoxProps) {
  return (
    <>
      {(text || '')
        .split('\n')
        .filter((text) => !!text.trim())
        .map((text, textIdx) => (
          <Box as="p" key={textIdx} {...props}>
            {text}
          </Box>
        ))}
    </>
  );
}

export const SectionHeading = ({ children, ...rest }) => (
  <Heading
    as="h2"
    fontSize={[5, 7]}
    fontWeight="bold"
    textAlign="center"
    maxWidth="800px"
    mx="auto"
    {...rest}
  >
    {children}
  </Heading>
);

export const SectionSubtitle = ({ children, ...rest }) => (
  <Box
    as="h3"
    fontSize={[2, 3]}
    textAlign="center"
    maxWidth={['calc(100% - 32px)', '700px']}
    fontWeight="body"
    mx="auto"
    color="gray.9"
    {...rest}
  >
    {children}
  </Box>
);

export const SectionTagline = ({ children, ...rest }) => (
  <Box
    fontSize={[2, 3]}
    textAlign="center"
    maxWidth={['calc(100% - 32px)', '700px']}
    fontWeight="heading"
    color="brand0.7"
    mx="auto"
    sx={{ textTransform: 'uppercase' }}
    {...rest}
  >
    {children}
  </Box>
);

export function GridContainer(props: BoxProps) {
  const theme = React.useContext<Theme>(ThemeContext as any);

  return (
    <Box
      __css={{
        mx: 'auto',
        width: '100%',
        gridGap: [3, 4],
        px: 3,
        py: 0,
        display: 'grid',
      }}
      maxWidth={theme.maxGridWidth}
      {...props}
    />
  );
}

interface PanelProps {
  prevBackground?: string;
  prevDecoration?: string;
  nextBackground?: string;
  nextDecorator?: string;
  decoration?: string;
  background?: string;
  sectionId?: string;
  isLast?: boolean;
  isFirst?: boolean;
  index?: number;
  backgroundImage?: any;
  ref?: React.Ref<HTMLDivElement>;
  children: React.ReactNode;
}

const fadeInUp = keyframes({
  from: {
    opacity: 0,
    transform: 'translate3d(0, 50px, 0)',
  },
  to: {
    opacity: 1,
    transform: 'none',
  },
});

// const slideInLeft = keyframes({
//   from: {
//     opacity: 0,
//     transform: 'translate3d(-100%, 0, 0)',
//     visibility: 'visible',
//   },
//   to: {
//     opacity: 1,
//     transform: 'none',
//   },
// });

// const slideInRight = keyframes({
//   from: {
//     opacity: 0,
//     transform: 'translate3d(100%, 0, 0)',
//     visibility: 'visible',
//   },
//   to: {
//     opacity: 1,
//     transform: 'none',
//   },
// });

const animations = [fadeInUp];

export function Panel({
  ref,
  children,
  decoration = 'none',
  background = 'light',
  prevBackground = 'light',
  nextBackground: nextBackground_ = 'light',
  prevDecoration = 'none',
  sectionId,
  isLast,
  isFirst,
  index,
  backgroundImage,
  ...rest
}: PanelProps & BoxProps) {
  const {
    ref: inViewRef,
    inView,
    entry,
  } = useInView({
    triggerOnce: true,
  });

  const nextBackground = isLast ? 'dark' : nextBackground_;

  const [show, setShow] = React.useState(isFirst);

  const contentRef = React.useRef<HTMLDivElement>(null);

  const setRefs = React.useCallback(
    (node) => {
      if (contentRef) {
        contentRef['current'] = node;
      }
      inViewRef(node);
    },
    [inViewRef]
  );

  const delay = 0;

  React.useEffect(() => {
    if (!show && inView) {
      setTimeout(() => {
        setShow(true);
      }, delay);
    }
  }, [inView, isFirst, show]);

  const theme = React.useContext(ThemeContext) as Theme;

  const topFill = themeGet(
    `colors.${
      {
        dark: 'brand0.9',
        gray: 'gray.0',
        light: 'white',
        bright: 'brand2.3',
      }[prevBackground]
    }`,
    'white'
  )({ theme });

  const bottomFill = themeGet(
    `colors.${
      {
        dark: 'brand0.9',
        gray: 'gray.0',
        light: 'white',
        bright: 'brand2.3',
      }[nextBackground]
    }`,
    'white'
  )({ theme });

  return (
    <ColorMode mode={background}>
      <Box
        ref={ref}
        color="text"
        width="100%"
        __css={{
          px: 0,
          ...({
            dark: {
              bg: 'brand0.1',
              // color: 'white',
            },
            gray: {
              bg: 'gray.0',
            },
            light: {},
            bright: {
              bg: 'brand2.3',
            },
          }[background] || {}),
          pt:
            decoration === 'none' ||
            (decoration !== 'none' && prevDecoration !== 'none')
              ? 5
              : 0,
          pb: decoration === 'none' ? 5 : 0,
        }}
        position="relative"
        {...rest}
      >
        {backgroundImage?.url && (
          <Box
            width="100%"
            height="100%"
            overflow="hidden"
            position="absolute"
            sx={{ top: 0 }}
          >
            <Image
              // priority
              lazyBoundary="600px"
              layout="fill"
              objectFit="cover"
              objectPosition="center"
              {...nextImageProps(backgroundImage)}
              alt="Hero"
            />
          </Box>
        )}

        {decoration !== 'none' && prevDecoration === 'none' && (
          <Box
            className={prevBackground + ' ' + background}
            as={DivToBottomRight}
            sx={{
              fill: topFill,
              display: 'block',
              transform: {
                slantUp: 'scaleY(-1)',
                slantDown: 'scaleY(-1) scaleX(-1)',
                taperLeft: 'scaleY(-1)',
                taperRight: 'scaleY(-1) scaleX(-1)',
              }[decoration],
              position: 'relative',
              top: '-1px',
            }}
          />
        )}
        <Box
          id={sectionId}
          sx={{
            position: 'relative',
            top: '-90px',
          }}
        />

        <Box pt={isFirst ? TOPBAR_HEIGHT : 0}></Box>

        <Box
          ref={setRefs}
          my={
            decoration !== 'none' && background !== nextBackground
              ? [4, 3, 0]
              : 0
          }
          pb={isLast ? 5 : undefined}
          className="client-side-hidden"
          sx={{
            animation:
              show && !isFirst
                ? `${animations[(index + 1) % animations.length]} 500ms`
                : undefined,
            visibility: show ? 'visible!important' : undefined,
            isolation: 'isolate', // Nes stacking context
          }}
        >
          {children}
        </Box>

        {decoration !== 'none' && background !== nextBackground && (
          <Box
            className={nextBackground + ' ' + background}
            as={DivToBottomRight}
            sx={{
              fill: bottomFill,
              display: 'block',
              transform: {
                slantUp: 'scaleX(-1)',
                slantDown: undefined,
                taperLeft: undefined,
                taperRight: 'scaleX(-1)',
              }[decoration],
              position: 'relative',
              bottom: '-1px',
            }}
          />
        )}
      </Box>
    </ColorMode>
  );
}

export const ImageEmbed = ({
  value,
  sizes,
  priority,
}: {
  value: any;
  sizes?: string;
  priority?: boolean;
}) => {
  return value.url ? (
    <Image
      lazyBoundary="600px"
      priority={priority}
      sizes={sizes}
      layout="responsive"
      alt="cta"
      {...nextImageProps(value)}
    />
  ) : null;
};

export const VideoEmbed = ({
  value,
  sizes,
  priority,
  ...rest
}: { value: any; sizes?: string; priority?: boolean } & BoxProps) => {
  const [isVideoOpen, setIsVideoOpen] = React.useState(false);

  return (
    <>
      <Box
        position="relative"
        borderRadius={2}
        overflow="hidden"
        onClick={() => setIsVideoOpen(true)}
        {...rest}
        // boxShadow="large"
      >
        {value.coverImageUrl ? (
          <Image
            lazyBoundary="600px"
            priority={priority}
            sizes={sizes}
            layout="responsive"
            {...nextImageProps({ ...value, url: value.coverImageUrl })}
            alt="cta"
          />
        ) : null}

        <Flex
          as="button"
          type="button"
          position="absolute"
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
          zIndex={1}
          sx={{ top: 0, cursor: 'pointer' }}
          color="invertedText"
        >
          <Box width="100px" height="100px" position="relative">
            <Flex
              bg="brand0.4"
              borderRadius="9999px"
              width="100px"
              height="100px"
              justifyContent="center"
              alignItems="center"
              opacity="0.7"
            ></Flex>
            <Box
              position="absolute"
              borderColor="transparent transparent transparent white"
              borderWidth="25px 0 25px 50px"
              borderStyle="solid"
              // marginLeft="12.5px"
              width="50px"
              height="50px"
              sx={{ top: '25px', left: '32.5px' }}
            />
          </Box>
        </Flex>
      </Box>

      <Modal
        isOpen={isVideoOpen}
        close={() => setIsVideoOpen(false)}
        size="xlarge"
      >
        {({ close }) => (
          <Box position="relative">
            <Box
              as="iframe"
              src={extractVideoUrl(value.videoEmbedUrl, {
                autoplay: 1,
                origin:
                  typeof window !== 'undefined'
                    ? location.protocol + '//' + location.host
                    : '',
              })}
              frameBorder={0}
              //@ts-ignore
              allow="autoplay; fullscreen"
              allowFullscreen={true}
              autoPlay={true}
              sx={{
                display: 'block',
                pointerEvents: 'all',
                width: '100%',
                height: '56.25vw',
                maxHeight: 'calc(1068px / 16 * 9)',
                '@supports(aspect-ratio: 16 / 9)': {
                  aspectRatio: '16 / 9',
                  height: 'unset',
                  maxHeight: 'unset',
                },
                boxShadow: 'rgb(16 25 40 / 8%) 0px 16px 32px 0px',
              }}
            />
            <CloseButton
              isDark={false}
              onClick={close}
              sx={{
                position: 'absolute',
                color: 'white',
                top: -20,
                right: -20,
              }}
            />
          </Box>
        )}
      </Modal>
    </>
  );
};
