import React from 'react';
import { Box, BoxProps, Flex } from '../grid';
import { Text } from '../typography';

// https://codepen.io/jensimmons/pen/KKPzxJa?editors=1100

type CheckboxProps = { size?: number } & Omit<BoxProps, 'size'>;

const Checkbox = (props: CheckboxProps) => {
  const size = props.size || 16;
  return (
    <Box
      as="input"
      type="checkbox"
      sx={{
        WebkitAppearance: 'none',
        width: `${size}px`,
        height: `${size}px`,
        cursor: 'pointer',
        borderRadius: 2,
        padding: 0,
        outline: 'none',
        display: 'inline-block',
        verticalAlign: 'text-top',
        backgroundColor: 'gray.2',

        '&:not(:hover):not(:focus):not(:checked)': {
          boxShadow: 'inset 0 0 0 ${size * 0.15}px rgba(0, 0, 0, 0.12)',
        },

        '&:hover': {
          backgroundColor: 'brand3.8',
        },

        '&:focus, &:hover': {
          boxShadow: `0 0 0 ${size * 0.15}px rgba(0, 0, 0, 0.12)`,
        },
        '&:checked': {
          position: 'relative',
          backgroundColor: 'brand3.8',
        },
        '&:checked::after': {
          position: 'absolute',
          left: `${size * 0.19}px`,
          top: `${size * 0.25}px`,
          content: "''",
          width: `${size * 0.5}px`,
          height: `${size * 0.2}px`,
          borderWidth: `${size * 0.15}px`,
          borderStyle: 'solid',
          borderColor: 'invertedText',
          borderRight: 'none',
          borderTop: 'none',
          transform: 'rotate(-45deg)',
        },
      }}
      {...props}
    />
  );
};

export const LabeledCheckbox = ({
  label,
  ...props
}: { label: React.ReactNode } & Omit<CheckboxProps, 'label'>) => {
  const size = props.size || 16;
  return (
    <Flex as="label" alignItems="center" sx={{ gap: '8px' }}>
      <Checkbox {...props} flex={`0 0 ${size}px`} />
      <Text flex="1 1 100%">{label}</Text>
    </Flex>
  );
};

export default Checkbox;
